import React, { useState, useEffect } from 'react';
import '../App.css';
import InfoIcon from '../assets/img/info.webp';
import axios from 'axios';
import ErrorMessage from '../components/ErrorMessage';
import ErrorIcon from '../assets/img/warning.webp';

function importAll(r) {
  let images = {};
  r.keys().forEach((item) => { images[item.replace('./', '').replace('.webp', '')] = r(item); });
  return images;
}

const flags = importAll(require.context('../assets/img/flags', false, /\.webp$/));

const countries = [
  { id: 'bd', name: 'Bangladesh', timezone: 'Asia/Dhaka', primaryDns: '154.205.144.126' },
  { id: 'br', name: 'Brazil', timezone: 'America/Sao_Paulo', primaryDns: '154.205.156.98' },
  { id: 'co', name: 'Colombia', timezone: 'America/Bogota', primaryDns: '91.148.134.48' },
  { id: 'eg', name: 'Egypt', timezone: 'Africa/Cairo', primaryDns: '38.54.59.154' },
  { id: 'in', name: 'India', timezone: 'Asia/Kolkata', primaryDns: '103.97.203.145' },
  { id: 'kg', name: 'Kyrgyzstan', timezone: 'Asia/Bishkek', primaryDns: '176.126.164.213' },
  { id: 'kz', name: 'Kazakhstan', timezone: 'Asia/Almaty', primaryDns: '38.180.38.117' },
  { id: 'ng', name: 'Nigeria', timezone: 'Africa/Lagos', primaryDns: '185.255.123.34' },
  { id: 'om', name: 'Oman', timezone: 'Asia/Muscat', primaryDns: '38.54.116.46' },
  { id: 'pk', name: 'Pakistan', timezone: 'Asia/Karachi', primaryDns: '223.165.5.27' },
  { id: 'ru', name: 'Russia', timezone: 'Europe/Moscow', primaryDns: '38.60.223.188' },
  { id: 'sg', name: 'Singapore', timezone: 'Asia/Singapore', primaryDns: '38.60.198.50' },
  { id: 'tr', name: 'Turkey', timezone: 'Europe/Istanbul', primaryDns: '154.205.148.146' },
  { id: 'mo', name: 'Morocco', timezone: 'Africa/Casablanca', primaryDns: '188.214.157.56' },
  { id: 'vn', name: 'Vietnam', timezone: 'Asia/Ho_Chi_Minh', primaryDns: '38.54.30.6' },
  { id: 'za', name: 'South Africa', timezone: 'Africa/Johannesburg', primaryDns: '197.189.207.21' },
  { id: 'iq', name: 'Iraq', timezone: 'Asia/Baghdad', primaryDns: '154.93.37.167' },
  { id: 'uae', name: 'UAE', timezone: 'Asia/Dubai', primaryDns: '154.90.55.37' },
  { id: 'jp', name: 'Japan', timezone: 'Asia/Tokyo', primaryDns: '103.75.118.96' },
  { id: 'mk', name: 'Macedonia', timezone: 'Europe/Skopje', primaryDns: '91.132.133.35' },
  { id: 'au', name: 'Australia', timezone: 'Australia/Sydney', primaryDns: '51.161.203.56' },
  { id: 'pe', name: 'Peru', timezone: 'America/Lima', primaryDns: '156.244.39.6' },
  {id: 'kh', name: 'Cambodia', timezone: 'Asia/Phnom_Penh', primaryDns: '38.54.4.167'}
];

const VpnCard = ({ title, helpText, max_Width }) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [locationChanged, setLocationChanged] = useState('');
  const primayDns = '94.131.109.50';
  const midpoint = Math.ceil(countries.length / 2);
  const firstHalfCountries = countries.slice(0, midpoint);
  const secondHalfCountries = countries.slice(midpoint);
  const [subscriptionStatus, setSubscriptionStatus] = useState('null');


  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const { data } = await axios.get('https://api.sweatdns.com/user/profile', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        console.log('data', data);
        setSubscriptionStatus(data.subscriptionStatus || 'inactive');
        if (data.selectedLocation && data.subscriptionStatus === 'active') {
          setSelectedCountry(data.selectedLocation);
        } else {
          clearSelectedLocation();
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching user profile:', error);
        setIsLoading(false);
      }
    };
    fetchUserProfile();
  }, []);


  const getTimeBasedStatus = (timezone) => {
    const now = new Date(new Date().toLocaleString('en-US', { timeZone: timezone }));
    const hour = now.getHours();

    if (hour >= 21 || hour < 4) {
      return { label: "🥵 Sweaty", className: "status-sweaty" };
    } else if (hour >= 4 && hour < 14) {
      return { label: "🔥 Botty", className: "status-botty" };
    } else if (hour >= 14 && hour < 18) {
      return { label: "🟠 Average", className: "status-average" };
    } else {
      return { label: "🔥 Botty", className: "status-botty" };
    }
  };

  const clearSelectedLocation = async () => {
    try {
      await axios.put('https://api.sweatdns.com/user/profile/clear-selected-location', {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setSelectedCountry('');
    } catch (error) {
      console.error('Error clearing selected location:', error);
    }
  };

  const addToAllowedList = async (countryId) => {
    try {
      if (!countryId) {
        console.error("countryId is required");
        return;
      }
      const response = await axios.put(`https://api.sweatdns.com/user/selected-location/${countryId}`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      console.log('IP added to allowed list');
      return response.data;
    } catch (error) {
      console.error('Error adding to allowed list:', error.response ? error.response.data : error.message);
    }
  };

  const handleCountryChange = async (countryId) => {
    if (subscriptionStatus !== 'active') {
      alert("You don't have an active subscription. Please purchase a plan to use our VPN.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.put(
        `https://api.sweatdns.com/user/selected-location/${countryId}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );

      if (response.status === 200) {
        setSelectedCountry(countryId);

        if (countryId === 'DisconnectPool') {
          setLocationChanged('VPN disconnected successfully.');
        } else {
          setLocationChanged(
            `Location changed to ${countries.find((c) => c.id === countryId)?.name || 'Unknown'}. Please restart your game!`
          );
          addToAllowedList(countryId);
        }
      } else {
        console.error('Failed to change location:', response.data.message);
        alert(response.data.message || 'Failed to change location');
      }
    } catch (error) {
      console.error('Error changing location:', error.response || error);
      alert('An error occurred while changing the location.');
    } finally {
      setIsLoading(false);
    }
  };

  const getLocalTime = (timezone) => {
    const now = new Date();
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: timezone,
    };
    const localTime = now.toLocaleTimeString('en-US', options);
    return localTime;
  };

  return (
    <div className="status-card" style={{ maxWidth: max_Width, margin: '0 auto' }}>
      <h4 className="status-header">{title}</h4>
      {isLoading && (
        <div className="loading-animation">
          <div className="loading-spinner"></div>
          <span className="loading-text">Changing location...</span>
        </div>
      )}
      {subscriptionStatus === 'inactive' && (
        <div
          className="inactive-subscription-message"
          style={{ maxWidth: '600px', margin: '0 auto' }}
        >
          <ErrorMessage
            message="You don't have any active subscription. Please purchase a plan."
            color="#fff"
            bgColor="#ff043a"
            image={ErrorIcon}
          />
        </div>
      )}
      {!isLoading && locationChanged && (
        <div
          className="location-changed"
          style={{
            background: '#222',
            borderRadius: '5px',
            maxWidth: '600px',
            margin: '0 auto',
            padding: '10px',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '10px',
          }}
        >
          <img
            src={InfoIcon}
            alt="Activated"
            style={{
              width: '25px',
              height: '25px',
              marginRight: '10px',
              marginLeft: '10px',
            }}
          />
          {locationChanged}
        </div>
      )}
      <div className="status-content">
        <p className="status-help-text">{helpText}</p>
        <div className="countries-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ul className="country-list" style={{ flex: 1 }}>
            {/* Disconnect Option */}
            <li className="country-item">
              <label>
                <input
                  type="radio"
                  name="country"
                  value="DisconnectPool"
                  checked={selectedCountry === 'DisconnectPool'}
                  onChange={() => handleCountryChange('DisconnectPool')}
                  className="hidden-checkbox"
                />
                <span
                  className={`custom-checkbox ${selectedCountry === 'DisconnectPool' ? 'checked' : ''}`}
                ></span>
                <img src={flags['disconnect']} alt="Disconnect" className="country-flag" />
                <div className="country-details">
                  <div className="country-name">Disconnect</div>
                </div>
              </label>
            </li>

            {firstHalfCountries.map((country) => (
              <li key={country.id} className="country-item">
                <label>
                  <input
                    type="radio"
                    name="country"
                    value={country.id}
                    checked={selectedCountry === country.id}
                    onChange={() => handleCountryChange(country.id)}
                    className="hidden-checkbox"
                  />
                  <span
                    className={`custom-checkbox ${selectedCountry === country.id ? 'checked' : ''
                      }`}
                  ></span>
                  <img src={flags[country.id]} alt={`${country.name} Flag`} className="country-flag" />
                  <div className="country-details">
                    <div className="country-name">{country.name}</div>
                    <div className="location-time">Local time: {getLocalTime(country.timezone)}</div>
                    <div className={getTimeBasedStatus(country.timezone).className}>
                      Possibly: {getTimeBasedStatus(country.timezone).label}
                    </div>
                  </div>
                </label>
              </li>
            ))}
          </ul>
          <ul className="country-list-2" style={{ flex: 1 }}>
            {secondHalfCountries.map((country) => (
              <li key={country.id} className="country-item">
                <label>
                  <input
                    type="radio"
                    name="country"
                    value={country.id}
                    checked={selectedCountry === country.id}
                    onChange={() => handleCountryChange(country.id)}
                    className="hidden-checkbox"
                  />
                  <span
                    className={`custom-checkbox ${selectedCountry === country.id ? 'checked' : ''
                      }`}
                  ></span>
                  <img src={flags[country.id]} alt={`${country.name} Flag`} className="country-flag" />
                  <div className="country-details">
                    <div className="country-name">{country.name}</div>
                    <div className="location-time">Local time: {getLocalTime(country.timezone)}</div>
                    <div className={getTimeBasedStatus(country.timezone).className}>
                      Possibly: {getTimeBasedStatus(country.timezone).label}
                    </div>
                  </div>
                </label>
              </li>
            ))}
          </ul>
        </div>
        <div className="dns-section">
          <div
            className="dns-section-header"
            style={{
              background: '#222',
              maxWidth: '400px',
              margin: '0 auto',
              marginTop: '30px',
              paddingRight: '10px',
              paddingLeft: '10px',
            }}
          >
            <div className="dns-selection-title">DNS Settings</div>
          </div>
          <div
            style={{
              background: '#444',
              maxWidth: '400px',
              margin: '0 auto',
              paddingRight: '10px',
              paddingLeft: '10px',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Primary DNS:</span>
              <span>38.60.196.214</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Secondary DNS:</span>
              <span>{primayDns}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VpnCard;

