import React, { useState, useEffect } from 'react';
import '../App.css';
import Activated from '../assets/img/activated.webp';
import Deactivated from '../assets/img/warning.webp';
import LoadingGif from '../assets/img/loading.gif';
import axios from 'axios';
import ErrorMessage from './ErrorMessage';
import InfoIcon from '../assets/img/info.png';

const StatusCard = ({ title, helpText, ipAddress, max_Width, setUserIp, setUserEmail }) => {
  const [userProfile, setUserProfile] = useState({});
  const [isActivated, setIsActivated] = useState(false);
  const [publicIp, setPublicIp] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const fetchProfile = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://api.sweatdns.com/user/profile', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      console.log("Fetched user profile:", response.data);
      setUserProfile(response.data);
      setIsActivated(!!response.data.networkIp);
      setUserIp(response.data.networkIp);
      setUserEmail(response.data.email);
    } catch (error) {
      console.error("Error fetching profile:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);


  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get('https://api.sweatdns.com/user/profile', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setUserProfile(response.data);
        setIsActivated(!!response.data.networkIp);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    const fetchPublicIP = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get('https://api.sweatdns.com/api/get-ip');
        setPublicIp(response.data.ip);
      } catch (error) {
        console.error('Error fetching public IP:', error);
        setPublicIp('Error fetching IP');
      } finally {
        setIsLoading(false);
      }
    };

    const compareNetworkIp = async () => {
      let currentIp = await getPublicIP();
      const response = await axios.get('https://api.sweatdns.com/user/profile', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      let networkIp = response.data.networkIp;

      if (networkIp === null) {
        return;
      }
      else if (networkIp !== currentIp) {
        setErrorMessage('Network is not same as the current IP address. Please reset your network!');
      }
    };

    fetchPublicIP();
    fetchProfile();
    compareNetworkIp();
  }, []);

  const getPublicIP = async () => {
    try {
      const response = await axios.get('https://api.sweatdns.com/api/get-ip');
      const ip = response.data.ip;
      return ip;
    } catch (error) {
      console.error('Error fetching public IP:', error.message);
      return null;
    }
  };

  async function handleNetworkAction() {
    setIsLoading(true);
    try {
      const ip = isActivated ? '' : await getPublicIP();
      const token = localStorage.getItem('token');
      let action;

      if (isActivated) {
        action = { networkIp: '' };
      } else {
        action = { networkIp: ip, checkReset: true };
      }

      const response = await axios.put('https://api.sweatdns.com/user/profile/network-reset', action, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.success) {
        fetchProfile();
        setErrorMessage('');
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setErrorMessage(error.response.data.message);
      } else {
        console.error('Error updating network status:', error);
        setErrorMessage('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <img src={LoadingGif} alt="Loading..." className="loading-gif" />;
  }

  return (
    <div className="status-card" style={{ maxWidth: max_Width, margin: '0 auto' }}>
      <h4 className="status-header">{title}</h4>
      <div className="status-content">
        <div className="alert-message" style={{ maxWidth: '600px', justifyContent: 'center', display: 'flex', margin: '0 auto' }}>
          {errorMessage && (
            <ErrorMessage message={errorMessage} color='#fff' bgColor='rgb(4 93 255)' image={InfoIcon} />
          )}
        </div>
        <p className="status-help-text">{helpText}</p>
        <div className="ip-address">IP Address: {publicIp || 'N/A'}</div>
        <div className={`authorize-network-section ${isActivated ? 'activated' : 'not-activated'}`} style={{ background: isActivated ? 'green' : '#ff043a', maxWidth: '600px', margin: 'auto', borderRadius: '5px', padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={isActivated ? Activated : Deactivated} alt="Network Status" style={{ width: '25px', height: '25px', marginRight: '10px', marginLeft: '10px' }} />
          {isActivated ? 'SweatDNS is activated on your network' : 'SweatDNS is not activated on your network'}
          <button onClick={handleNetworkAction} className="reset-network-authorize-button" style={{ alignItems: 'right' }}>
            {isActivated ? 'Deactivate Network' : 'Activate Network'}
          </button>
        </div>
        <p className="authorize-network-disclaimer" style={{ fontSize: '13px' }}>Make sure that your gaming device is on the same network.</p>
        <p className="reset-network-disc" style={{ fontSize: '13px' }}>⚠️ <strong>You can deactivate your Network once every 24 hours</strong></p>
      </div>
    </div>
  );
};

export default StatusCard;
