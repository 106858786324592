import React, { useState } from "react";
import ChatCard from "./ChatCard";
import "../App.css";

const FloatingButton = ({ networkIp, userEmail }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
      setIsChatOpen(!isChatOpen);
  };

  console.log("Passing userEmail to ChatCard:", userEmail); 

  return (
      <div className="floating-container">
          {isChatOpen && (
              <ChatCard
                  onClose={toggleChat}
                  networkIp={networkIp}
                  userEmail={userEmail} 
              />
          )}
          <button className="floating-button" onClick={toggleChat}>
              💬
          </button>
      </div>
  );
};


export default FloatingButton;
