import React from "react";

const AnnouncementBar = () => {

    return (
        <div className="announcement-bar">
        <div className="announcement-bar-text">
            <p>
            <span style={{ color: "#f00" }}>🔥</span> <strong>NEW:</strong> SweatDNS <strong>no longer</strong> require to change DNS for every location!</p>
        </div>
        </div>
    );
}

export default AnnouncementBar;