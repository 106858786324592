const regionMappings = {
  Europe: ["DE", "FR", "ES", "IT", "GB", "NL", "BE", "CH", "SE", "NO", "FI", "PL", "CZ", "AT", "HU", "DK", "IE", "PT", "GR", "LU", "EE", "LV", "LT", "BG", "RO", "SK", "SI"],
  NA: ["US", "CA", "MX"],
  Asia: ["CN", "JP", "IN", "KR", "ID", "PH", "TH", "MY", "VN", "PK", "BD", "SG"],
  Oceania: ["AU", "NZ", "FJ", "PG"],
  ME: ["SA", "AE", "QA", "KW", "OM", "IQ", "EG", "IR", "IL"],
  Africa: ["ZA", "NG", "KE", "GH", "TZ", "UG", "DZ", "MA"],
};

export const fetchRegionByCountry = (countryCode) => {
  for (let region in regionMappings) {
      if (regionMappings[region].includes(countryCode)) {
          return region;
      }
  }
  return "Unknown Region";
};
const fetchRegionAndTime = async (ip) => {
  console.log("Fetching region and time for IP:", ip); // Debug log
  try {
      const response = await fetch(`https://freeipapi.com/api/json/${ip}`);
      const data = await response.json();
      console.log("Free IP API Response:", data); // Debug log

      if (data && data.countryCode && data.timeZones?.length > 0) {
          const { countryCode, timeZones } = data;

          const region = fetchRegionByCountry(countryCode);

          const now = new Date();
          const localHour = new Intl.DateTimeFormat("en-US", {
              timeZone: timeZones[0],
              hour: "numeric",
              hour12: false,
          }).format(now);

          const roundedHour = Math.round(parseInt(localHour, 10)); // Round to nearest hour

          return { region, userTime: roundedHour };
      } else {
          console.error("Invalid response from Free IP API:", data);
          return { region: "Unknown Region", userTime: 0 };
      }
  } catch (error) {
      console.error("Error fetching region and time:", error);
      return { region: "Unknown Region", userTime: 0 };
  }
};
