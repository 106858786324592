import React, { useState, useEffect } from 'react';
import axios from 'axios';
import loadingGif from '../assets/img/loading.gif';
import InfoIcon from '../assets/img/info.webp';
import ActivatedIcon from '../assets/img/activated.webp';
import { useNavigate } from 'react-router-dom';
import { DnsLeakReport } from '../utils/DnsLeakReport';

const countryCodes = {
    'bh': 'Bahrain',
    'bd': 'Bangladesh',
    'br': 'Brazil',
    'ca': 'Canada',
    'co': 'Colombia',
    'cr': 'Costa Rica',
    'de': 'Germany',
    'eg': 'Egypt',
    'in': 'India',
    'ke': 'Kenya',
    'kg': 'Kyrgyzstan',
    'kz': 'Kazakhstan',
    'lv': 'Latvia',
    'mo': 'Morocco',
    'mm': 'Myanmar',
    'mx': 'Mexico',
    'ng': 'Nigeria',
    'om': 'Oman',
    'pk': 'Pakistan',
    'pe': 'Peru',
    'ru': 'Russia',
    'sg': 'Singapore',
    'tr': 'Turkey',
    'uk': 'United Kingdom',
    'vn': 'Vietnam',
    'za': 'South Africa',
    'kh': 'Cambodia',
};

const DnsLookup = () => {
    const [addresses, setAddresses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [activated, setActivated] = useState(false);
    const [selectedCountryName, setSelectedCountryName] = useState('');
    const [id, setId] = useState("");

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        const fetchDnsAddresses = async () => {
            const apiUrl = 'https://api.sweatdns.com/user/profile';

            try {
                const { data: userData } = await axios.get(apiUrl, { headers: { Authorization: `Bearer ${token}` } });
                const selectedLocation = userData.selectedLocation;
                //console.log('SELECTED LOCATION',selectedLocation)
                const report = new DnsLeakReport();
                const dns = await report.report();
                //console.log('DNS',dns);
                setId(report.reportId);
                const isActivated = dns.some(v => {
                    if (selectedLocation === 'bd') {
                        return ['bd', 'sg', 'in'].includes(v.country); 
                    } 
                    if (selectedLocation === 'bh') {
                        return ['bh', 'fr'].includes(v.country); 
                    }
                    if (selectedLocation === 'eg') {
                        return ['eg', 'ch'].includes(v.country); 
                    }
                    if (selectedLocation === 'za') {
                        return ['ke', 'ch'].includes(v.country); 
                    }
                    if (selectedLocation === 'mm') {
                        return ['mm', 'sg'].includes(v.country); 
                    }
                    if (selectedLocation === 'pe') {
                        return ['pe', 'cl'].includes(v.country); 
                    }
                    if (selectedLocation === 'sa') {
                        return ['sa', 'it'].includes(v.country); 
                    }
                    if (selectedLocation === 'ma') {
                        return ['ma', 'sp'].includes(v.country); 
                    }
                    if (selectedLocation === 'vn') {
                        return ['vn', 'sg'].includes(v.country); 
                    }
                    if (selectedLocation === 'za') {
                        return ['za', 'ky'].includes(v.country); 
                    }
                    if (selectedLocation === 'ng') {
                        return ['ng', 'uk'].includes(v.country); 
                    }
                    if (selectedLocation === 'kg') {
                        return ['kg', 'fi', 'kz'].includes(v.country); 
                    }
                    else {
                        return v.country === selectedLocation; 
                    }
                });

                if (isActivated) {
                    setActivated(true); // This is the correct way to set state
                    setSelectedCountryName(countryCodes[selectedLocation] || 'N/A');

                } else {
                    setActivated(false); // Set to false if not activated
                }

                if (dns?.error || !dns) {
                    setError("Failed to fetch DNS addresses");
                } else {
                    setAddresses(dns.map(v => v.ip));
                    setError('');
                }
            } catch (err) {
                setError('Failed to fetch DNS addresses');
                console.error(err);
            }
        };

        fetchDnsAddresses();

        const timer = setTimeout(() => {
            setLoading(false);
        }, 10000);

        return () => clearTimeout(timer);
    }, []);


    return (
        <div className="background-container" style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', background: 'rgba(0,0,0,0.7)' }}>
            {loading ? (
                <div className="loading-gif-container">
                    <h2>Running DNS checks</h2>
                    <p>Please wait while we check your DNS settings. This can take a few seconds.</p>
                    <img src={loadingGif} style={{ height: '50px', width: '50px' }} alt="Loading..." />
                </div>
            ) : error ? (
                <div className="error-container">{error}</div>
            ) : (
                <div className='dns-status-container' style={{ textAlign: 'center' }}>
                    <h2>DNS Check Result</h2>
                    {activated ? (
                        <div>
                            <img className='info-icon-container' src={ActivatedIcon} style={{ height: '50px', width: '50px' }} alt="info icon" />
                            <div>SweatDNS is activated on your network.</div>
                            <p style={{ maxWidth: '500px' }}>
                                You are connected to: <strong>{selectedCountryName}</strong>. You can now start your game and enjoy the full benefits of our VPN. If you need help reach us out on <a className="dns-support-links" href="https://discord.com/sweatvpn">Discord</a> or send an email to <a className="dns-support-links" href="mailto:support@sweatdns.com">support@sweatdns.com</a>.
                            </p>
                            <button className='back-profile-button' onClick={() => navigate('/account')}>Back to profile</button>
                        </div>
                    ) : (
                        <div>
                            <img className='info-icon-container' src={InfoIcon} style={{ height: '50px', width: '50px' }} alt="info icon" />
                            <div>SweatDNS isn't activated on your network.</div>
                            <p style={{ maxWidth: '500px' }}>Please try again, if you need help please open a ticket in our <a className="dns-support-links" href="https://discord.com/sweatvpn">Discord</a> or send an email to <a className="dns-support-links" href="mailto:help@sweatvpn.com">help@sweatvpn.com</a>.</p>
                            <p style={{ maxWidth: '500px', fontSize: '12px'}}>Report ID: #{id}.</p>
                            <button className='try-again-button' onClick={() => window.location.reload()}>Try Again</button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default DnsLookup;
