import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../App.css';
import CouponManager from './CouponManager';
import PaidOrders from './PaidOrders';

const Admin = () => {
    const [activatedUsers, setActivatedUsers] = useState([]);
    const [error, setError] = useState('');
    const [loading] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const getSubscriptionStatus = (code) => {
        switch (code) {
          case '0': return 'No Subscription';
          case '1': return 'Trial';
          case '2': return 'Month';
          case '3': return 'Year';
          default: return 'N/A';
        }
      };

      useEffect(() => {
        const fetchActivatedUsers = async () => {
            try {
                const response = await axios.get('https://api.sweatdns.com/admin/users', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                setActivatedUsers(response.data);
            } catch (error) {
                console.error('Error fetching activated users:', error);
                setError('Failed to fetch activated users');
            }
        };

        fetchActivatedUsers();
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    const handleSelectChange = (userId) => {
        setSelectedUsers(prevSelectedUsers =>
          prevSelectedUsers.includes(userId)
            ? prevSelectedUsers.filter(id => id !== userId)
            : [...prevSelectedUsers, userId]
        );
      };

      const handleResetNetwork = () => {
        console.log("Resetting network for users: ", selectedUsers);
      };

    return (
        <div className="admin-container" style={{ padding: '20px' }}>
            <h1 style={{ marginTop: '100px' }}>Admin Dashboard</h1>
            <p>Welcome to the admin dashboard</p>
            <div className="admin-content">
                <div className="coupon-manager-container">
                <h2 className="coupon-manager-header">Active Users</h2>
                <button onClick={handleResetNetwork} style={buttonStyle}>Reset Network</button>
                <button  style={buttonStyle} > Refund </button>
                <button  style={buttonStyle} > Ban </button>
                <button  style={buttonStyle} > Add month </button>
                {loading ? (
                <p>Loading coupons...</p>  ) :
               (
                <div className="admin-grid" style={{ overflowY: 'scroll', maxHeight: '400px' }}>
                    <table className="admin-table">
                        <thead>
                            <tr>
                                <th>Action</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Status</th>
                                <th>Subscription</th>
                                <th>Trial</th>
                                <th>Last network reset</th>
                                <th>IP</th>
                                <th>Refunded</th>
                                <th>Selected location</th>
                                <th>Payment</th>
                                <th>Last Payment</th>
                                <th>Created at</th>
                                <th>Expires at</th>

                            </tr>
                        </thead>
                        <tbody>
                            {activatedUsers.map((user, index) => (
                                <tr key={user._id} style={{ background: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}>
                                     <input
                                     type="checkbox"
                                     checked={selectedUsers.includes(user._id)}
                                     onChange={() => handleSelectChange(user._id)}
                                    />
                                    <td>{`${user.firstName} ${user.lastName}`}</td>
                                    <td>{user.email}</td>
                                    <td>{user.subscriptionStatus}</td>
                                    <td>{getSubscriptionStatus(user.subscription)}</td>
                                    <td>{user.isTrial? 'Yes' : 'No'}</td>
                                    <td>{user.lastNetworkReset ? user.lastNetworkReset : 'N/A'}</td>
                                    <td>{user.ip}</td>
                                    <td>{user.refunded ? 'Yes' : 'No'}</td>
                                    <td>{user.selectedLocation || 'N/A'}</td>
                                    <td>{user.payment || 'N/A'}</td>
                                    <td>{user.lastPaymentDate || 'N/A'}</td>
                                    <td>{user.createdAt || 'N/A'}</td>
                                    <td>{user.endsAt || 'N/A'}</td>                          
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>         
              )}</div>
              </div>
              <CouponManager />
              <PaidOrders users={activatedUsers}/>
        </div>
    );
};


export default Admin;


const buttonStyle = {
    transition: 'background-color 0.3s ease', padding: '10px', backgroundColor: '#333', color: 'white', border: 'none', borderRadius: '3px', cursor: 'pointer', fontSize: '16px', marginTop: '10px', maxWidth: '200px', margin: '0 auto', marginLeft: '5px'
};
