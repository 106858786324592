import React, { useState, useEffect } from "react";
import TypingEffect from "./TypingEffect";
import "../App.css";
import { fetchRegionByCountry } from "./ipRegion";

const ChatCard = ({ onClose, networkIp , userEmail}) => {
    const [messages, setMessages] = useState([]);
    const [selectedGameMode, setSelectedGameMode] = useState("");
    const [loading, setLoading] = useState(false);
    const [isTyping, setIsTyping] = useState(false);

    const gameModes = [
        "Battle Royale Solo",
        "Battle Royale Duo",
        "Battle Royale Trio",
        "Battle Royale Quads",
        "Resurgence Solo",
        "Resurgence Duo",
        "Resurgence Trio",
        "Resurgence Quads",
        "Multiplayer",
    ];

    const countryMap = {
        ID: "Indonesia",
        KG: "Kyrgyzstan",
        GR: "Greece",
        SP: "Spain",
        NG: "Nigeria",
        TH: "Thailand",
        KZ: "Kazakhstan",
        IL: "Israel",
        ZA: "South Africa",
        SG: "Singapore",
        UAE: "United Arab Emirates",
        JP: "Japan",
        IN: "India",
        KR: "South Korea",
        AUS: "Australia",
        SAU1: "Saudi Arabia",
        TR: "Turkey",
        OM: "Oman",
        KY: "Kenya",
        RU: "Russia",
        VT: "Vietnam",
        AL: "Albania",
        AR: "Argentina",
        HK: "Hong Kong",
        UA: "Ukraine",
        CR: "Costa Rica",
        MO: "Morocco",
        MX: "Mexico",
        EC: "Ecuador",
        EG: "Egypt",
        BD: "Bangladesh",
        CY: "Cyprus",
        RS: "Serbia",
        BR: "Brazil",
        PK: "Pakistan",
        PH: "Philippines",
        SE: "Sweden",
        SAU2: "Saudi Arabia",
        CL: "Chile",
        MY: "Malaysia",
        PT: "Portugal",
        GT: "Guatemala",
        PE: "Peru",
        PR: "Puerto Rico",
        CO: "Colombia",
        RO: "Romania",
        DE: "Germany",
        CZ: "Czech Republic",
        LV: "Latvia",
        UK: "United Kingdom",
        CA: "Canada",
        IQ: "Iraq",
        CG: "Congo",
        GH: "Ghana",
        MN: "Mongolia",
        BT: "Bhutan",
        BZ: "Belize",
        UZ: "Uzbekistan",
        NP1: "Nepal",
        NP2: "Nepal",
        GL: "Greenland",
        BO: "Bolivia",
        BH: "Bahrain",
        MM: "Myanmar",
        PA: "Panama",
        QA: "Qatar",
        TN: "Tunisia",
        LB: "Lebanon",
        AZ: "Azerbaijan",
        GE: "Georgia",
    };


    const getFlagUrl = (country) => {
        const countryCodeMap = {
            'California': 'us',
            'Missouri': 'us',
            'Utah': 'us',
            'Florida': 'us',
            'New Jersey': 'us',
            'Washington': 'us',
            'Georgia': 'us',
            'New York': 'us',
            'Illinois': 'us',
            'Texas': 'us',
            'Italy': 'it',
            'Spain': 'es',
            'Germany': 'de',
            'France': 'fr',
            'United Kingdom': 'gb',
            'Australia': 'au',
            'Japan': 'jp',
            'Singapore': 'sg',
            'Hong Kong': 'hk',
            'Brazil': 'br',
            'Luxembourg': 'lu',
            'Poland': 'pl',
            'Netherland': 'nl',
            'Korea': 'kr',
            'Bahrain': 'bh',
            'Chile': 'cl',
            'South Africa': 'za',
            'AUS': 'au',
            'SAU1': 'sa',
            'SAU2': 'sa',
            'KY': 'ke',
            'MO': 'ma',
            'IS': 'il',
            'NP1': 'np',
            'NP2': 'np',
            'NP': 'np',
            'SAU1': 'sa',
            'SAU2': 'sa',
            'UAE': 'ae',
            'UAE1': 'ae',
            'UAE2': 'ae',
            'UK': 'gb',
            'VT': "vn"
        };

        const countryCode = countryCodeMap[country] || country.toLowerCase();

        return `https://flagcdn.com/24x18/${countryCode}.png`;
    };

    const fetchRegionAndTime = async (ip) => {
        console.log("Fetching region and time for IP:", ip); // Debug log
        try {
            const response = await fetch(`https://freeipapi.com/api/json/${ip}`);
            const data = await response.json();
            console.log("Free IP API Response:", data); // Debug log
    
            if (data && data.countryCode && data.timeZones?.length > 0) {
                const { countryCode, timeZones } = data;
    
                // Use fetchRegionByCountry to get the region
                const region = fetchRegionByCountry(countryCode);
    
                // Calculate the current local hour using the primary timezone
                const now = new Date();
                const localHour = new Intl.DateTimeFormat("en-US", {
                    timeZone: timeZones[0],
                    hour: "numeric",
                    hour12: false,
                }).format(now);
    
                const roundedHour = Math.round(parseInt(localHour, 10)); // Round to nearest hour
    
                return { region, userTime: roundedHour };
            } else {
                console.error("Invalid response from Free IP API:", data);
                return { region: "Unknown Region", userTime: 0 };
            }
        } catch (error) {
            console.error("Error fetching region and time:", error);
            return { region: "Unknown Region", userTime: 0 };
        }
    };

    const queueMessages = (newMessages) => {
        let delay = 0;
        newMessages.forEach((msg) => {
            delay += 1000;
            setTimeout(() => {
                setMessages((prev) => [...prev, msg]);
            }, delay);
        });
    };

    const checkAndReduceTokens = async () => {
        console.log("Checking tokens for email:", userEmail); // Debug log
    
        if (!userEmail) {
            console.error("User email is null or undefined.");
            queueMessages([{ type: "bot", text: "⚠️ Unable to fetch your email address." }]);
            return false;
        }
    
        try {
            const response = await fetch(`https://api.sweatdns.com/user/tokens/${userEmail}`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
            });
            
    
            const data = await response.json();
            console.log("Token API Response:", data); // Debug log
    
            if (response.ok) {
                console.log("Tokens updated successfully.");
                return true;
            } else {
                console.error("Token validation failed:", data.message);
                queueMessages([
                    { type: "bot", text: "🚫 You do not have enough tokens to make a prediction. You get 5 daily tokens." },
                ]);
                return false;
            }
        } catch (error) {
            console.error("Error in token reducer:", error);
            queueMessages([
                { type: "bot", text: "⚠️ An error occurred while checking your tokens." },
            ]);
            return false;
        }
    };

    useEffect(() => {
        console.log("ChatCard received networkIp:", networkIp); // Debug log

        // Queue initial bot messages
        queueMessages([
            { type: "bot", text: "👋 Hey Sweat! Welcome to Sweat-AI." },
            {
                type: "bot",
                text: "By selecting a gamemode from the dropdown below, I will try to find the best possible servers for you based on your Region, Time, and gamemode.",
            },
            { type: "bot", text: "⚠️ Please check for rotations before picking a gamemode." },
        ]);
    }, [networkIp]);


    const handleSendRequest = async () => {
        if (!selectedGameMode) {
            queueMessages([{ type: "bot", text: "Please select a game mode first." }]);
            return;
        }

        setLoading(true);

        try {

            const hasTokens = await checkAndReduceTokens();
            if (!hasTokens) {
              setLoading(false);
              return; 
            }

            console.log("Fetching Region and Time for:", networkIp); // Debug log
            const { region, userTime } = await fetchRegionAndTime(networkIp);

            console.log("Sending Region and Time:", { region, userTime }); // Debug log

            if (region === "Unknown Region" || userTime === 0) {
                queueMessages([
                    { type: "bot", text: "Error fetching your region or time. Please try again." },
                ]);
                setLoading(false);
                return;
            }

            const gameMode = mapGameModeToApiValue(selectedGameMode);

            queueMessages([
                {
                    type: "bot",
                    text: `You selected ${selectedGameMode}. Let me provide you with the best VPN prediction for this mode.`,
                },
            ]);

            const response = await fetch("https://web-production-0754.up.railway.app/predict", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    type: "dns_location",
                    region,
                    gamemode: gameMode,
                    time: userTime,
                }),
            });

            if (!response.ok) throw new Error("Prediction request failed");

            const data = await response.json();
            console.log("Prediction Response:", data); // Debug log

            if (data.top_3_locations) {
                const predictions = data.top_3_locations.map((location) => ({
                    countryCode: location.Location,
                    probability: location.Probability,
                }));

                queueMessages([
                    { type: "bot", text: "Prediction:" },
                    ...predictions.map((prediction) => ({
                        type: "prediction",
                        text: "",
                        flag: getFlagUrl(prediction.countryCode),
                        countryName: countryMap[prediction.countryCode] || prediction.countryCode,
                        probability: `${prediction.probability}%`,
                    })),
                    {
                        type: "bot",
                        text: "📊 Probability indicates the chance of finding a less competitive lobby in the mentioned location.",
                    },
                ]);
            } else {
                queueMessages([{ type: "bot", text: "No predictions available right now." }]);
            }
        } catch (error) {
            console.error("Error fetching prediction:", error);
            queueMessages([{ type: "bot", text: "Error fetching prediction. Please try again." }]);
        } finally {
            setLoading(false);
            setIsTyping(false);
        }
    };

    const mapGameModeToApiValue = (mode) => {
        switch (mode) {
            case "Battle Royale Solo":
            case "Battle Royale Duo":
            case "Battle Royale Trio":
            case "Battle Royale Quads":
                return `BR: ${mode.split(" ")[2].toLowerCase()}`;
            case "Resurgence Solo":
            case "Resurgence Duo":
            case "Resurgence Trio":
            case "Resurgence Quads":
                return `RG: ${mode.split(" ")[1].toLowerCase()}`;
            case "Multiplayer":
                return "MP";
            default:
                return mode;
        }
    };

    return (
        <div className="chat-card">
            <div className="chat-header">
                <h3>Sweat-AI (Beta)</h3>
                <button onClick={onClose}>✖</button>
            </div>
            <div className="chat-log">
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        className={
                            msg.type === "user"
                                ? "user-message"
                                : msg.type === "bot"
                                    ? "bot-message"
                                    : "prediction-message"
                        }
                    >
                        {msg.flag && <img src={msg.flag} alt="flag" className="flag-icon" />}
                        {msg.text}{" "}
                        {msg.countryName && (
                            <strong>
                                {msg.countryName} {msg.probability}
                            </strong>
                        )}
                    </div>
                ))}
                {isTyping && <p className="bot-message">Sweat-AI is typing...</p>}
            </div>
            <div className="chat-input-container">
                <select
                    value={selectedGameMode}
                    onChange={(e) => setSelectedGameMode(e.target.value)}
                    className="game-mode-select"
                >
                    <option value="">Select a game mode</option>
                    {gameModes.map((mode, index) => (
                        <option key={index} value={mode}>
                            {mode}
                        </option>
                    ))}
                </select>
                <button onClick={handleSendRequest} disabled={loading}>
                    Send
                </button>
            </div>
        </div>
    );
};

export default ChatCard;