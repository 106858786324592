import React, { useState } from 'react';
import '../App.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import ErrorMessage from '../components/ErrorMessage';
import ErrorIcon from '../assets/img/warning.webp'; 

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();
  
  const handleEmailChange = (event) => {
    setEmail(event.target.value.toLowerCase());
    if (errorMessage) {
      setErrorMessage('');
    }
  };
  
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    try {
      const response = await axios.post('https://api.sweatdns.com/user/login', { email, password });
      if (response.data.success) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userId', response.data.userId);
        login(response.data.token);
        navigate('/account');
      } else {
        setErrorMessage(response.data.message || 'Invalid credentials. Please try again.');
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message || 'An error occurred. Please try again.');
      } else if (error.request) {
        setErrorMessage('No response from server. Please check your connection.');
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
      console.error('Login error:', error.response || error.request || error.message);
    }
  };

  return (  
    <div className="signup-container">
      <div className="login-form-container">
        <h2 className="signup-header">LOGIN TO YOUR <span className="highlight">SWEATDNS</span> ACCOUNT</h2>
        <p>Login and start dominating Warzone 4</p>
        {errorMessage && <ErrorMessage message={errorMessage} bgColor= '#ff043a' color= '#fff' image={ErrorIcon}  />}
        <form onSubmit={handleSubmit} className="signup-form">
          <label>Email</label>
          <input type="email" value={email} onChange={handleEmailChange} placeholder="Enter your email" required style={{ marginBottom: '20px', padding: '10px', backgroundColor: '#222', border: 'none', color: '#fff', borderRadius: '5px'}}/>
          <label>Choose password</label>
          <input type="password" value={password} onChange={handlePasswordChange} placeholder="Enter your password" required style={{ marginBottom: '20px', padding: '10px', backgroundColor: '#222', border: 'none', color: '#fff', borderRadius: '5px'}} />
          <button type="submit" className="signup-button">LOGIN</button>
          <p className="terms-text">Forgot your password? <a href="/reset-password"><span className="login-link" >Reset password</span></a></p>
          <p className="already-account" style={{fontSize: '0.8em'}}>If you don't have an account, <a href="/sign-up"><span className="login-link" >create account</span></a></p>
        </form>
      </div>
    </div>
  );
};

export default Login;
